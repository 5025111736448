import { AgreementKeys } from '../../../constants'

const NavigationBtns = ({
  prevStep,
  nextStep,
  step,
  acceptedTerms,
  downloadAgreement,
  downloading,
  cco,
  knowCCOLicense,
  selectedAgreements,
}) => {
  return (
    <div className="agreement-interface-nav-btns">
      <button className="back" onClick={prevStep} disabled={downloading}>
        Back
      </button>
      <button
        className="next"
        onClick={step === 5 ? downloadAgreement : nextStep}
        disabled={
          (!cco &&
            step === 3 &&
            !selectedAgreements[AgreementKeys.RoyaltyLength]) ||
          (knowCCOLicense && !cco) ||
          (step === 5 && !acceptedTerms.agree1 && !cco) ||
          downloading ||
          (step === 4 &&
            cco &&
            selectedAgreements.ccoBoilerplate.filter(
              ({ value }) => value === true,
            )?.length !== selectedAgreements.ccoBoilerplate.length) ||
          (step === 3 &&
            cco &&
            (!selectedAgreements?.ccoAttribution[0]?.value ||
              !selectedAgreements?.ccoAttribution[1]?.value ||
              !selectedAgreements?.ccoAttribution[4]?.value))
        }>
        {step === 4 ? 'Review' : step === 5 ? 'Agree & Create' : 'Next'}
      </button>
    </div>
  )
}
export default NavigationBtns
