import { useSelector } from 'react-redux'
import { AgreementContentType, AgreementKeys } from '../../../constants'
import { AgreementItem } from './AgreementItem'
import Spinner from '../../../components/common/spinner/Spinner'
import { Fragment, useEffect, useState } from 'react'
import { DropdownIcon } from '../../../components/common/icons/DropdownIcon'
import {
  sortAgreements,
  triggerGoogleAnalyticsEvent,
} from '../../../utils/Helper'

const RoyaltyContent = ({
  data,
  licenseData,
  addAgreement,
  removeAgreement,
  selectedAgreements,
  editingAgreement,
  setEditAgreement,
  agreementContent,
  setAgreementContent,
  cancelEditAgreement,
  saveUpdatedAgreement,
  cco,
  onChangeAtrributionForm,
  perpetualLicenseAgreementsCodes,
}) => {
  const {
    agreements: { loading },
    auth: { user },
  } = useSelector((state) => state)
  const [showFirstContent, setShowFirstContent] = useState(true)
  const [showSecondContent, setShowSecondContent] = useState(true)
  const [dataDeletionFull, setDataDeletionFull] = useState(false)

  const royalLengthSelection = selectedAgreements[AgreementKeys.RoyaltyLength]
  const royalValueSelection = selectedAgreements[AgreementKeys.RoyaltyValue]
  const royaltyAcknowledgementSelection =
    selectedAgreements[AgreementKeys.RoyaltyAcknowledgement]

  const licenseSelections = selectedAgreements[AgreementKeys.License]
  const assignmentSelections = selectedAgreements[AgreementKeys.Assignment]

  const changeInput = (e) => {
    const { name, value } = e.target
    addAgreement(
      name,
      // name === AgreementKeys.RoyaltyLength ? Number(value) : value,
      value,
    )
  }
  const titles = data.filter(
    (x) => x.contentType === AgreementContentType.TITLE,
  )
  const inputs = sortAgreements(
    data.filter((x) => x.contentType === AgreementContentType.INPUT),
  )
  const allLicensePerpetualAgreements = licenseData.reduce((acc, agreement) => {
    if (perpetualLicenseAgreementsCodes.includes(agreement.code)) {
      acc.push(agreement._id)
    }
    return acc
  }, [])
  const perpetualLicenseSelected = licenseSelections.some((x) =>
    allLicensePerpetualAgreements.includes(x.index),
  )
  const radios = sortAgreements(
    data.filter(
      (x) =>
        x.contentType === AgreementContentType.RADIO &&
        (perpetualLicenseSelected || assignmentSelections.length
          ? x.content === 'Perpetual'
          : x.content),
    ),
  )
  const spans = data.filter((x) => x.contentType === AgreementContentType.SPAN)

  const perpetualSubtitles = sortAgreements(
    data.filter(
      (x) =>
        x.contentType === AgreementContentType.SUBTITLE &&
        x.code.includes('PERPETUAL'),
    ),
  )
  const perpetualParagraphs = sortAgreements(
    data.filter(
      (x) =>
        x.contentType === AgreementContentType.PARAGRAPH &&
        x.code.includes('PERPETUAL'),
    ),
  )
  const boilerplateOls = sortAgreements(
    data.filter((x) => x.contentType === AgreementContentType.OL),
  )
  const perpetualAgreement = data.find((x) => x.code === 'ROYALTY_RADIO_4')?._id

  useEffect(() => {
    triggerGoogleAnalyticsEvent('setting_agreement', {
      stage: 'royalty',
      userId: user?.id,
    })
  }, [])

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="assignment-content">
      {!cco && (
        <DropdownIcon
          className={`dropdown${showFirstContent ? ' open' : ''}`}
          onClick={() => setShowFirstContent(!showFirstContent)}
        />
      )}
      {!cco &&
        titles.map((title, index) => (
          <Fragment key={index}>
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={title}
            />
          </Fragment>
        ))}
      {!cco && (
        <div className="questionaire">
          <form>
            {showFirstContent && inputs.length && (
              <div className="tikbox-form-element" style={{}}>
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={{
                    ...inputs[0],
                    contentType: AgreementContentType.LABEL,
                  }}
                  htmlFor="royalty"
                />
                <input
                  style={{ height: 40, marginLeft: 10 }}
                  onChange={changeInput}
                  className="royalty"
                  type="number"
                  name="royaltyValue"
                  id="royalty"
                  value={royalValueSelection}
                />
              </div>
            )}
            {showFirstContent && (
              <div className="tikbox-form-element">
                <label className="length" htmlFor="1">
                  Length
                </label>
                <div className="length-inputs">
                  {radios.map((radio, index) => {
                    const isChecked = royalLengthSelection === radio._id
                    return (
                      <Fragment key={index}>
                        <AgreementItem
                          editingAgreement={editingAgreement}
                          setEditAgreement={setEditAgreement}
                          cancelEditAgreement={cancelEditAgreement}
                          saveUpdatedAgreement={saveUpdatedAgreement}
                          agreementContent={agreementContent}
                          setAgreementContent={setAgreementContent}
                          agreement={radio}
                          isChecked={isChecked}
                          addAgreement={addAgreement}
                          removeAgreement={removeAgreement}
                        />
                      </Fragment>
                    )
                  })}
                </div>
              </div>
            )}
            {inputs.length > 1 && (
              <div
                className="tikbox-form-element"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                }}>
                <DropdownIcon
                  style={{ top: 20 }}
                  className={`dropdown${showSecondContent ? ' open' : ''}`}
                  onClick={() => setShowSecondContent(!showSecondContent)}
                />
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={{
                    ...inputs[1],
                    contentType: AgreementContentType.PARAGRAPH,
                  }}
                />
                {showSecondContent && (
                  <input
                    style={{ height: 40, minWidth: 400, width: '100%' }}
                    onChange={changeInput}
                    className="royalty"
                    name="royaltyAcknowledgement"
                    id="royalty"
                    value={royaltyAcknowledgementSelection}
                  />
                )}
                {showSecondContent && spans.length && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={spans[0]}
                  />
                )}
              </div>
            )}
          </form>

          {perpetualAgreement ===
            selectedAgreements[AgreementKeys.RoyaltyLength] && (
            <>
              {perpetualSubtitles[0] && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={perpetualSubtitles[0]}
                />
              )}
              <div>
                {perpetualSubtitles[1] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualSubtitles[1]}
                  />
                )}
                {perpetualParagraphs[0] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualParagraphs[0]}
                  />
                )}
              </div>
              <div>
                {perpetualSubtitles[2] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualSubtitles[2]}
                  />
                )}
                {perpetualParagraphs[1] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualParagraphs[1]}
                  />
                )}
              </div>
              <div>
                {perpetualSubtitles[3] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualSubtitles[3]}
                  />
                )}
                {perpetualParagraphs[2] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualParagraphs[2]}
                  />
                )}
              </div>
              <div>
                {perpetualSubtitles[4] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualSubtitles[4]}
                  />
                )}
                {perpetualParagraphs[3] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualParagraphs[3]}
                  />
                )}
              </div>
              <div>
                {perpetualSubtitles[5] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualSubtitles[5]}
                  />
                )}
                {perpetualParagraphs[4] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualParagraphs[4]}
                  />
                )}
              </div>
              {perpetualSubtitles[6] && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={perpetualSubtitles[6]}
                />
              )}
              <div
                className={`legal-clause-container${
                  dataDeletionFull ? ' full' : ' summary'
                }`}>
                {perpetualParagraphs[5] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualParagraphs[5]}
                    hideToggleFull={true}
                    showFullAgreement={dataDeletionFull}
                  />
                )}

                {boilerplateOls.length && (
                  <ol>
                    {boilerplateOls.map((ol, index) => (
                      <Fragment key={index}>
                        <AgreementItem
                          editingAgreement={editingAgreement}
                          setEditAgreement={setEditAgreement}
                          cancelEditAgreement={cancelEditAgreement}
                          saveUpdatedAgreement={saveUpdatedAgreement}
                          agreementContent={agreementContent}
                          setAgreementContent={setAgreementContent}
                          agreement={ol}
                          hideToggleFull={true}
                          showFullAgreement={dataDeletionFull}
                        />
                      </Fragment>
                    ))}
                  </ol>
                )}
                {dataDeletionFull && (
                  <span
                    onClick={() => setDataDeletionFull(false)}
                    className="toggler">
                    See summary clause
                  </span>
                )}
                {!dataDeletionFull && (
                  <span
                    onClick={() => setDataDeletionFull(true)}
                    className="toggler">
                    See legal clause
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      )}

      {/* CCO ATTRIBUTION */}
      {cco && (
        <div className="cco-attribution">
          <h2>Attribution</h2>
          <form>
            <div className="attribution-input">
              <label>
                Title of Work <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                value={selectedAgreements.ccoAttribution[0].value}
                className="input"
                type="text"
                placeholder="This work"
                name="workTitle"
                onChange={onChangeAtrributionForm}
              />
            </div>
            <div className="attribution-input">
              <label>
                Creator or Work <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                value={selectedAgreements.ccoAttribution[1].value}
                className="input"
                type="text"
                placeholder="Jane Doe"
                name="workCreator"
                onChange={onChangeAtrributionForm}
              />
            </div>
            <div className="attribution-input">
              <label>
                Year Of Creation <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                value={selectedAgreements.ccoAttribution[4].value}
                className="input"
                type="text"
                placeholder="YYYY"
                name="creationYear"
                onChange={onChangeAtrributionForm}
              />
            </div>
            <div className="attribution-input">
              <label>Link to Work</label>
              <input
                value={selectedAgreements.ccoAttribution[2].value}
                className="input"
                type="text"
                placeholder="https://janedoe.com/best-photo-ever.jpg"
                name="workLink"
                onChange={onChangeAtrributionForm}
              />
            </div>
            <div className="attribution-input">
              <label>Link to Creator Profile</label>
              <input
                value={selectedAgreements.ccoAttribution[3].value}
                className="input"
                type="text"
                placeholder="https://jabedoe.com"
                name="creatorProfileLink"
                onChange={onChangeAtrributionForm}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default RoyaltyContent
