export const getRgbColor = (color) => {
  if (typeof color === 'string' && color.indexOf('#') > -1) {
    return color
  }

  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
}

export const triggerGoogleAnalyticsEvent = (eventName, metaData) => {
  window.gtag('event', eventName, metaData)
}

export const getNameIntials = (name) => {
  let initials = ''
  const [firstName, lastName] = name.split(' ')
  if (firstName) {
    initials += firstName[0].toUpperCase()
  }
  if (lastName) {
    initials += lastName[0].toUpperCase()
  }
  return initials
}

export const roundDown = (value) => {
  return Math.floor(value * 10) / 10
}

export const getAssetInMB = (sizeByte) => {
  return roundDown(sizeByte / 1000000)
}

export const getLocation = async () => {
  const location = await fetch('https://ipwho.is/?fields=country,ip').then(
    (r) => r.json(),
  )
  return location
}

export const sortAgreements = (agreements) => {
  return agreements.sort((a, b) => {
    const numA = parseInt(a.code.split('_')[2])
    const numB = parseInt(b.code.split('_')[2])
    return numA - numB
  })
}

export const copyTextToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      alert('Copied to clipboard!')
    })
    .catch((err) => {
      console.error('Failed to copy: ', err)
      throw err
    })
}
