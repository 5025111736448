import _ from 'lodash'
import { toast } from 'react-toastify'
import instance, { blobInstance } from '../utils/Axios'
/**
 * Save Captured video upload data.
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const assetsUpload = (data) => {
  return new Promise((resolve, reject) => {
    instance
      .post('/assets/asset-upload', data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => reject(err))
  })
}

export const libraryFilesUpload = (data) => {
  return new Promise((resolve, reject) => {
    instance
      .post('/user-libraries/files-upload', data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        toast.error(
          'There is a problem while uploading the file. Please try again later',
        )
        reject(err)
      })
  })
}

export const libraryFilesDelete = (ids) => {
  return new Promise((resolve, reject) => {
    instance
      .delete(`/user-libraries?ids=${ids}`)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        toast.error(
          'There is a problem while deleting file. Please try again later',
        )
        reject(err)
      })
  })
}

/**
 * update library video crop data
 * @param data
 * @returns {Promise<unknown>}
 */
export const libraryCropData = async (data) => {
  try {
    return await instance.post('/user-libraries/crop-data', data)
  } catch (error) {
    const errorMessage = error.response?.data?.message || error?.message
    throw new Error(errorMessage)
  }
}

/**
 *  merge audio and create mp3 version
 * @param data
 * @returns {Promise<unknown>}
 */
export const createMergedMp3Recording = async (blobs) => {
  try {
    const formData = new FormData()
    _.forEach(blobs, (blob, index) => {
      formData.append('files', blob, `audio-${index}.wav`)
    })
    const response = await blobInstance.post('/voiceover/create-mp3', formData)
    return response.data
  } catch (error) {
    const errorMessage = error.response?.data?.message || error?.message
    throw new Error(errorMessage)
  }
}

/**
 * verify media
 * @param data
 * @returns {Promise<unknown>}
 */
export const verifyMedia = async (data) => {
  try {
    const verifiedData = await instance.post('/assets/verify', data)
    const url = verifiedData?.data?.message?.url
    if (verifiedData && url) {
      const response = await fetch(url)
      const content = await response.text()
      if (content) {
        const [name, rights, ref] = content.split('|')
        if (!name || !rights || !ref) {
          throw new Error('Not able to verify media')
        }
        const spliRights = rights.split(',')
        const license = spliRights
          .find((x) => x.includes('license'))
          .split('license')[1]
        const assign = spliRights
          .find((x) => x.includes('assign'))
          .split('assign')[1]
        const royalty = spliRights
          .find((x) => x.includes('royalty'))
          .split('royalty')[1]
        const dataMining = spliRights
          .find((x) => x.includes('data-mining'))
          .split('data-mining')[1]
        return {
          name,
          ref: ref.replace(' ', ''),
          license,
          assign,
          royalty,
          dataMining,
        }
      }
    }
    throw new Error('Not able to verify media')
  } catch (error) {
    const errorMessage = error.response?.data?.message || error?.message
    throw new Error(errorMessage)
  }
}

/**
 * verify media
 * @param data
 * @returns {Promise<unknown>}
 */
export const getAssetAgreement = async (assetRef) => {
  try {
    const assetAgreement = await instance.get(`/assets/${assetRef}/agreement`)
    if (assetAgreement?.data?.result === 'success') {
      return assetAgreement?.data?.message
    }
    throw new Error('Not able to get asset agreement')
  } catch (error) {
    const errorMessage = error.response?.data?.message || error?.message
    throw new Error(errorMessage)
  }
}

/**
 * verify media
 * @param data
 * @returns {Promise<unknown>}
 */
export const agreeToAgreementTerms = async (reference) => {
  try {
    const assetAgreement = await instance.post(
      '/assets/agreement/agree-to-terms',
      { reference },
    )
    if (assetAgreement?.data?.result === 'success') {
      return assetAgreement?.data?.message
    }
    throw new Error('Not able to agree to terms')
  } catch (error) {
    const errorMessage = error.response?.data?.message || error?.message
    throw new Error(errorMessage)
  }
}

export default {
  createMergedMp3Recording,
  libraryCropData,
  libraryFilesUpload,
}
