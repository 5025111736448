/* eslint-disable quotes */
import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Pagination, Popconfirm, Tooltip } from 'antd'
import Loader from 'react-loader-spinner'
import { faFileContract } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SidePanel from '../../../components/common/side-panel/SidePanel'
import Spinner from '../../../components/common/spinner/Spinner'
import MainPanel from '../../../components/common/main-panel/MainPanel'
import { getUserAgreements } from '../../../redux/actions/UserAgreementActions'
import {
  deleteAsset,
  getAssets,
  setSignAsset,
} from '../../../redux/actions/AssetActions'
import moment from 'moment'
import { AGREEMENT } from '../../../constants/Routes'
import FileUtils, { FileTypes } from '../../../utils/FileUtils'
import { DropdownIcon } from '../../../components/common/icons/DropdownIcon'
import { SearchIcon } from '../../../components/common/icons/SearchIcon'
import { DownloadIcon } from '../../../components/common/icons/DownloadIcon'
import { EditIcon } from '../../../components/common/icons/EditIcon'
import { SoundPreviewIcon } from '../../../components/common/icons/SoundPreviewIcon'
import { VideoPreviewIcon } from '../../../components/common/icons/VideoPreviewIcon'
import {
  copyTextToClipboard,
  triggerGoogleAnalyticsEvent,
} from '../../../utils/Helper'
import { AssetDeleteIcon } from '../../../components/common/icons/DeleteIcon'
import TikBoxModal from '../../../components/common/modal/Modal'
import instance from '../../../utils/Axios'
import { toast } from 'react-toastify'
import { SearchIconThick } from '../../../components/common/icons/SearchIconThick'

const itemsPerPage = 10

function divideIntoChunks(array, numChunks) {
  const chunks = Array.from({ length: numChunks }, () => [])
  array.forEach((item, index) => {
    chunks[index % numChunks].push(item)
  })
  return chunks
}

const searchColumns = 3

const itemRender = (_, type, originalElement) => {
  if (type === 'prev') {
    return <a>Prev</a>
  }
  if (type === 'next') {
    return <a>Next</a>
  }
  return originalElement
}

const AssetsContent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    userAgreements,
    assets,
    auth: { user },
  } = useSelector((state) => state)
  const imageSearchRetry = useRef(0)

  const emptySearchResults = [[], [], []]

  const [assetsData, setAssetsData] = useState(assets.data)
  const [totalRoyalty, setTotalRoyalty] = useState(0)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [statusFilter, setStatusFilter] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [paginatedItems, setPaginatedItems] = useState([])
  const [openSearchEngineModal, setOpenSearchEngineModal] = useState(false)
  const [searchingSearchEngine, setSearchingSearchEngine] = useState(false)
  const [searchResults, setSearchResults] = useState(emptySearchResults)
  const [openLicenseBtnGenerateModal, setOpenLicenseBtnGenerateModal] =
    useState(false)
  const [assetGenerateLicenseBtn, setAssetGenerateLicenseBtn] = useState(null)

  const toggleDropdown = (value) => {
    setOpenDropdown(value)
  }

  const changeStatusFilter = (value) => {
    setStatusFilter(value)

    triggerGoogleAnalyticsEvent('assets_status_filter', {
      value,
      userId: user?.id,
    })
    const newAssetData = assets.data.filter((asset) => {
      const userAgreement = userAgreements.data.find(
        (x) => x.assetId === asset._id,
      )
      const truthy =
        value === 'Asserted'
          ? userAgreement && !userAgreement?.isDraft
          : value === 'Draft'
          ? !userAgreement || userAgreement?.isDraft
          : value === 'Gen-AI'
          ? asset.aiTool
          : value === 'Signed'
          ? false
          : true
      return truthy
    })
    setAssetsData(newAssetData)
    setCurrentPage(1)
    setOpenDropdown(false)
  }

  const searchAssets = () => {
    const value = searchFilter
    const newAssetData = assets.data.filter((asset) => {
      if (
        asset.originalFileName?.includes(value) ||
        asset.reference?.includes(value) ||
        asset.fileKey?.includes(value)
      ) {
        return true
      }
      return false
    })
    setAssetsData(newAssetData)
    setCurrentPage(1)
  }

  const handleGetUserAgreements = async () => {
    await dispatch(getUserAgreements())
  }

  const handleGetAssets = async () => {
    await dispatch(getAssets())
  }

  const handleSaveAsset = async (asset) => {
    await dispatch(setSignAsset([asset]))
    triggerGoogleAnalyticsEvent('edit_asset', {
      assetId: asset?._id,
      userId: user?.id,
    })
    history.push(AGREEMENT, { editAsset: asset })
  }

  const handleDeleteAsset = async (id) => {
    await dispatch(deleteAsset(id))
    triggerGoogleAnalyticsEvent('delete_asset', {
      assetId: id,
      userId: user?.id,
    })
  }

  const toggleSearchEngineModal = (value) => {
    setOpenSearchEngineModal(value)
  }

  const searchEngineWithAsset = async (assetId) => {
    try {
      setSearchResults(emptySearchResults)
      setSearchingSearchEngine(true)
      const searchData = await instance.get(
        `/assets/search-engine?assetId=${assetId}`,
      )
      const dividedInColumns = divideIntoChunks(
        searchData.data.message,
        searchColumns,
      )
      setSearchResults(dividedInColumns)
      setSearchingSearchEngine(false)
    } catch (error) {
      if (imageSearchRetry.current < 2) {
        imageSearchRetry.current = imageSearchRetry.current + 1
        return searchEngineWithAsset(assetId)
      } else {
        imageSearchRetry.current = 0
        setSearchingSearchEngine(false)
        toast.error(
          "We're having trouble with this image. The team has been alerted - please try another image for now",
        )
      }
    }
  }

  const handleSearchEngineModal = async (assetId) => {
    triggerGoogleAnalyticsEvent('search_engine_search', {
      userId: user?.id,
      assetId,
    })
    setOpenSearchEngineModal(!openSearchEngineModal)
    searchEngineWithAsset(assetId)
  }

  const handleOpenLicenseBtnGenerateModal = async (assetId) => {
    triggerGoogleAnalyticsEvent('license_btn_generate_modal', {
      userId: user?.id,
      assetId,
    })
    setOpenLicenseBtnGenerateModal(!openLicenseBtnGenerateModal)
    setAssetGenerateLicenseBtn(assetId)
  }

  const toggleLicenseNowGenerateModal = (value) => {
    setOpenLicenseBtnGenerateModal(value)
  }

  // Invoke when user click to request another page.
  const handlePageClick = (value) => {
    setCurrentPage(value)
  }

  const getTotalRoyalty = () => {
    return userAgreements.data.reduce((acc, item) => {
      if (!item.isDraft && item.royaltyValue) {
        acc += item.royaltyValue
      }
      return acc
    }, 0)
  }

  useEffect(() => {
    if (assetsData.length) {
      const count = assetsData?.reduce((acc, asset) => {
        const userAgreement = userAgreements.data.find(
          (x) => x.assetId === asset._id,
        )
        if (userAgreement && userAgreement.royaltyValue) {
          acc += userAgreement.royaltyValue
        }
        return acc
      }, 0)
      setTotalRoyalty(count)
    } else {
      setTotalRoyalty(0)
    }
  }, [assetsData, setTotalRoyalty, userAgreements.data])

  useEffect(() => {
    handleGetAssets()
    handleGetUserAgreements()
  }, [])

  useEffect(() => {
    setAssetsData(assets.data)
  }, [assets.loading])

  useEffect(() => {
    const offset = (currentPage - 1) * itemsPerPage
    const paginatedAssets = assetsData.slice(offset, offset + itemsPerPage)
    setPaginatedItems(paginatedAssets)
  }, [currentPage, assetsData])

  useEffect(() => {
    window.addEventListener('click', (e) => {
      let hasDropdownClass = false
      e.target.classList.forEach((className) => {
        if (
          className === 'status-text' ||
          className === 'status-text-content' ||
          className === 'dropdown-container' ||
          className === 'dropdown-toggler'
        ) {
          hasDropdownClass = true
        }
      })
      if (!hasDropdownClass) {
        toggleDropdown(false)
      }
    })
    return () => {
      window.removeEventListener('click', () => {
        toggleDropdown(false)
      })
    }
  }, [])

  return (
    <MainPanel>
      <div className="assets-container">
        <div className="assets-container__header">
          <h2 className="title">Assets</h2>
          <div className="status-filter">
            <div
              className="dropdown-container tikbox-center"
              onClick={() => toggleDropdown(true)}>
              <div className="status-text">
                <span className="status-text-content">
                  {statusFilter ? statusFilter : 'Status: All'}
                </span>
                <DropdownIcon
                  onClick={() => toggleDropdown(true)}
                  className="dropdown-toggler"
                />
              </div>
              <div
                className={`dropdown-container__dropdown${
                  openDropdown ? ' open' : ''
                }`}>
                <ul>
                  <li onClick={() => changeStatusFilter('')}>Status: All</li>
                  <li onClick={() => changeStatusFilter('Asserted')}>
                    Asserted
                  </li>
                  <li onClick={() => changeStatusFilter('Signed')}>Signed</li>
                  <li onClick={() => changeStatusFilter('Gen-AI')}>Gen-AI</li>
                  <li onClick={() => changeStatusFilter('Draft')}>Draft</li>
                </ul>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                searchAssets()
              }}
              className="search-input">
              <input
                value={searchFilter}
                placeholder="Search"
                onChange={(e) => setSearchFilter(e.target.value)}
              />
              <SearchIcon
                onClick={searchAssets}
                style={{ cursor: 'pointer' }}
              />
            </form>
          </div>
        </div>

        <div className="assets-table">
          <div className="assets-table__header">
            <div className="assets-table__row">
              <div className="tikbox-col">
                <span>Name ({assetsData.length})</span>
              </div>
              <div className="tikbox-col">
                <span>Status</span>
              </div>
              <div className="tikbox-col">
                <span>Royalty {statusFilter ? `(£${totalRoyalty})` : ''}</span>
              </div>
              <div className="tikbox-col">
                <span>Date</span>
              </div>
              <div className="tikbox-col">
                <span>Agreement</span>
              </div>
            </div>
          </div>
          <div className="assets-table__body">
            {!paginatedItems.length ? (
              <div style={{ textAlign: 'center' }}>No assets</div>
            ) : (
              paginatedItems.map((asset, index) => {
                const userAgreement = userAgreements.data.find(
                  (x) => x.assetId === asset._id,
                )
                const royaltyVal = userAgreement?.royaltyValue
                const isAsserted = userAgreement && !userAgreement?.isDraft
                return (
                  <div className="assets-table__row" key={index}>
                    <div className="tikbox-col name">
                      {FileUtils.getFileType(asset.fileType) ===
                        FileTypes.imageType &&
                      asset?.processing &&
                      !asset.deletedAt ? (
                        <Loader
                          type="Oval"
                          visible={true}
                          height="20"
                          width="20"
                          color="#0daa85"
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : FileUtils.getFileType(asset.fileType) ===
                        FileTypes.imageType ? (
                        <img
                          src={asset.fileTagUrl || asset.fileUrl}
                          alt="asset"
                        />
                      ) : FileUtils.getFileType(asset.fileType) ===
                        FileTypes.audioType ? (
                        <div className="media-bg">
                          <SoundPreviewIcon />
                          <p>Audio</p>
                        </div>
                      ) : FileUtils.getFileType(asset.fileType) ===
                        FileTypes.videoType ? (
                        <div className="media-bg">
                          <VideoPreviewIcon />
                          <p>Video</p>
                        </div>
                      ) : null}

                      <span style={{ marginLeft: 5 }}>
                        {asset?.processing && !asset.deletedAt ? (
                          'File is processing. Check back later'
                        ) : !asset.deletedAt ? (
                          <a
                            target="_blank"
                            href={asset.fileTagUrl || asset.fileUrl}
                            rel="noreferrer">
                            {asset.originalFileName}
                          </a>
                        ) : null}
                      </span>
                    </div>
                    <div className="tikbox-col">
                      <div
                        className={`tikbox-col__content${
                          asset?.aiTool
                            ? ' gen-ai'
                            : asset?.deletedAt
                            ? ' deleted'
                            : !isAsserted
                            ? ' draft'
                            : ' asserted'
                        }`}>
                        <span className="title">Status</span>
                        <span className="content" style={{ marginLeft: 5 }}>
                          {asset?.aiTool
                            ? 'Gen-AI'
                            : asset?.deletedAt
                            ? 'Deleted'
                            : !isAsserted
                            ? 'Draft'
                            : 'Asserted'}
                        </span>
                      </div>
                    </div>
                    <div className="tikbox-col">
                      <div className="tikbox-col__content">
                        <span className="title">Royalty</span>
                        <span>{royaltyVal ? `£${royaltyVal}` : '-'}</span>
                      </div>
                    </div>
                    <div className="tikbox-col">
                      <div className="tikbox-col__content">
                        <span className="title">Date</span>
                        <span>{moment(asset.date).format('DD/MM/YYYY')}</span>
                      </div>
                    </div>
                    <div className="tikbox-col">
                      {!asset.deletedAt &&
                        !userAgreement?.fromCCO &&
                        !asset.aiTool && (
                          <Tooltip title="Edit">
                            <button
                              onClick={() => handleSaveAsset(asset)}
                              className="button edit">
                              <EditIcon />{' '}
                              {/* <span style={{ marginLeft: 3 }}>Edit</span> */}
                            </button>
                          </Tooltip>
                        )}
                      {isAsserted && (
                        <Tooltip title="Download PDF">
                          <a
                            className={`button download${
                              isAsserted ? ' asserted' : ''
                            }`}
                            href={userAgreement?.agreementUrl}
                            target="_blank"
                            rel="noreferrer">
                            <DownloadIcon />{' '}
                            {/* <span style={{ marginLeft: 3 }}>Download PDF</span> */}
                          </a>
                        </Tooltip>
                      )}
                      {!asset.deletedAt && (
                        <Tooltip title="Delete">
                          <Popconfirm
                            title="Delete the asset"
                            description="Are you sure to delete this asset?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleDeleteAsset(asset._id)}>
                            <button className="button delete">
                              <AssetDeleteIcon style={{ stroke: '#0daa85' }} />{' '}
                            </button>
                          </Popconfirm>
                        </Tooltip>
                      )}
                      <div className="agreement-seperator"></div>
                      <Tooltip title="Search">
                        <button
                          className="button search"
                          onClick={() => handleSearchEngineModal(asset._id)}>
                          <SearchIconThick style={{ cursor: 'pointer' }} />{' '}
                        </button>
                      </Tooltip>
                      {!asset.aiTool && (
                        <Tooltip title="Generate Verify">
                          <button
                            className="button license"
                            onClick={() =>
                              handleOpenLicenseBtnGenerateModal(asset._id)
                            }>
                            <FontAwesomeIcon
                              color="#5a5757"
                              icon={faFileContract}
                            />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                )
              })
            )}
          </div>
        </div>

        {paginatedItems.length ? (
          <div className="assets-pagination">
            <Pagination
              current={currentPage}
              onChange={handlePageClick}
              total={assetsData.length}
              showSizeChanger={false}
              itemRender={itemRender}
            />
          </div>
        ) : null}
      </div>
      <TikBoxModal
        open={openLicenseBtnGenerateModal}
        maxW="600px"
        toggle={toggleLicenseNowGenerateModal}
        title="Generate license button">
        <div className="generate-license-btn-modal">
          <div class="code-container">
            <pre id="htmlCode">
              &lt;button width="560" height="315" id="tikbox-license-now-btn"
              onclick="tikboxNavigateToVerifyAsset('{process.env.REACT_APP_URL}
              /verify?assetId={assetGenerateLicenseBtn}')" &gt; License Now
              &lt;/button&gt;
              <br />
              &lt;link rel="stylesheet" href="{process.env.REACT_APP_SERVER_URL}
              /api/assets/license-now.css" /&gt;
              <br />
              &lt;script async defer integrity="
              {process.env.REACT_APP_LICENSE_INTEGRITY}" crossorigin="anonymous"
              src="{process.env.REACT_APP_SERVER_URL}
              /api/assets/license-now.js" &gt; &lt;/script&gt;
            </pre>
            <button
              class="copy-btn"
              onClick={() =>
                copyTextToClipboard(`
                  <button width="560" height="315" onclick="tikboxNavigateToVerifyAsset('${process.env.REACT_APP_URL}/verify?assetId=${assetGenerateLicenseBtn}')" id="tikbox-license-now-btn"> License Now
                  </button>
                  <link rel="stylesheet" href="${process.env.REACT_APP_SERVER_URL}/api/assets/license-now.css" />
                  <script async defer integrity="${process.env.REACT_APP_LICENSE_INTEGRITY}" crossorigin="anonymous" src="${process.env.REACT_APP_SERVER_URL}/api/assets/license-now.js"> </script>
                `)
              }>
              Copy Code
            </button>
          </div>
        </div>
      </TikBoxModal>
      <TikBoxModal
        open={openSearchEngineModal}
        maxW={window.innerWidth > 1000 ? '60%' : '90%'}
        toggle={toggleSearchEngineModal}
        title={
          searchingSearchEngine
            ? 'Searching for possible matches'
            : 'These possible matches of your asset have been found'
        }>
        <div className="search-engine-modal">
          {searchingSearchEngine ? (
            <div className="search-loader">
              <p>Searching...</p>
            </div>
          ) : (
            <div className="search-results">
              {!searchResults[0].length ? <p>No result found</p> : null}
              {searchResults[0].length ? (
                <>
                  <div className="search-results__column">
                    {searchResults[0].map((item, index) => {
                      return (
                        <a
                          className="result"
                          href={item.link}
                          key={index}
                          target="_blank"
                          rel="noreferrer">
                          <img
                            className="result-img"
                            src={item.image}
                            alt="result"
                          />
                          <div className="result-title">
                            {item.logo && (
                              <img
                                alt="source-icon"
                                className="source-icon"
                                src={item.logo}
                              />
                            )}
                            <p>{item.source}</p>
                          </div>
                          <div className="result-details">
                            <p>{item.title}</p>
                          </div>
                        </a>
                      )
                    })}
                  </div>
                  <div className="search-results__column">
                    {searchResults[1].map((item, index) => {
                      return (
                        <a
                          className="result"
                          href={item.link}
                          key={index}
                          target="_blank"
                          rel="noreferrer">
                          <img
                            className="result-img"
                            src={item.image}
                            alt="result"
                          />
                          <div className="result-title">
                            {item.logo && (
                              <img
                                alt="source-icon"
                                className="source-icon"
                                src={item.logo}
                              />
                            )}
                            <p>{item.source}</p>
                          </div>
                          <div className="result-details">
                            <p>{item.title}</p>
                          </div>
                        </a>
                      )
                    })}
                  </div>
                  <div className="search-results__column">
                    {searchResults[2].map((item, index) => {
                      return (
                        <a
                          className="result"
                          href={item.link}
                          key={index}
                          target="_blank"
                          rel="noreferrer">
                          <img
                            className="result-img"
                            src={item.image}
                            alt="result"
                          />
                          <div className="result-title">
                            {item.logo && (
                              <img
                                alt="source-icon"
                                className="source-icon"
                                src={item.logo}
                              />
                            )}
                            <p>{item.source}</p>
                          </div>
                          <div className="result-details">
                            <p>{item.title}</p>
                          </div>
                        </a>
                      )
                    })}
                  </div>
                </>
              ) : null}
            </div>
          )}
        </div>
      </TikBoxModal>
    </MainPanel>
  )
}

const Assets = () => {
  const [uploading, setUploading] = useState(false)
  return (
    <div className="tikbox-assets">
      <div className="tikbox-assets__content">
        <SidePanel />
        {uploading ? <Spinner /> : <AssetsContent />}
      </div>
    </div>
  )
}

export default Assets
