export const AgreementKeys = {
  Assignment: 'assignment',
  License: 'license',
  RoyaltyValue: 'royaltyValue',
  RoyaltyLength: 'royaltyLength',
  RoyaltyAcknowledgement: 'royaltyAcknowledgement',
}

export const AgreementCategory = {
  ASSIGNMENT: 'assignment',
  LICENSE: 'license',
  ROYALTY: 'royalty',
  BOILERPLATE: 'boilerplate',
}

export const AgreementContentType = {
  TITLE: 'title',
  SUBTITLE: 'sub-title',
  PARAGRAPH: 'paragraph',
  CHECKBOX: 'checkbox',
  INPUT: 'input',
  RADIO: 'radio',
  SPAN: 'span',
  OL: 'ol',
  LABEL: 'LABEL',
}

export const ASSET_LIBRARY = {
  VIDEO: {
    type: 'video',
    label: 'Video',
    acceptedFormats: 'video/*',
    extensions: 'MP4, AVI, MOV, FLV, WMV',
  },
  SOUND: {
    type: 'music',
    label: 'Sound',
    acceptedFormats: 'audio/*',
    extensions: 'MP3, WMA, OGG',
  },
  IMAGE: {
    type: 'image',
    label: 'Photo',
    acceptedFormats: 'image/*',
    extensions: 'JPEG, PNG, GIF',
  },
  PDF: {
    type: 'pdf',
    label: 'PDF',
    acceptedFormats: 'application/pdf',
    extensions: 'PDF',
  },
}

export const ASSET_UPLOAD_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
}

export const LabellingOptions = [
  'License',
  'License with GenAI',
  'GenAI labelling',
]
