import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAssets,
  resetSigningAssets,
} from '../../../redux/actions/AssetActions'
import { getUserAgreements } from '../../../redux/actions/UserAgreementActions'
import MainPanel from '../../../components/common/main-panel/MainPanel'
import SidePanel from '../../../components/common/side-panel/SidePanel'
import AssetsUploadSection from '../../../components/common/assets-upload-section/AssetsUploadSection'
import TikBoxModal from '../../../components/common/modal/Modal'
import { Select } from 'antd'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'
import instance from '../../../utils/Axios'

const industries = [
  'Media & Entertainment',
  'Photography',
  'Film & Television Production',
  'Advertising & Marketing',
  'Social Media & Influencer Management',
  'Publishing & Journalism',
  'Art & Design',
  'Corporate Communications',
  'Legal & Compliance',
  'Fashion & E-commerce',
  'Museums & Cultural Heritage',
  'Education & Research',
  'Healthcare & Medical Imaging',
  'Real Estate & Architecture',
  'Government & Public Sector , Other',
]

const industriesRoles = [
  'Content Creator (e.g., photographers, videographers, digital artists)',
  'Copyright & Licensing Manager (e.g., responsible for managing IP rights)',
  'Creative Director (e.g., overseeing content creation and branding)',
  'Social Media Manager (e.g., influencer managers, social media strategists)',
  'Producer (e.g., film, video, or multimedia producers)',
  'Marketing & Communications Specialist (e.g., digital marketing, PR managers)',
  'AI/ML Specialist (e.g., working with generative AI content)',
  'Legal Counsel (e.g., IP lawyers, legal advisors for media/tech)',
  'Brand Manager (e.g., protecting brand integrity, managing brand assets)',
  'Talent Agent or Manager (e.g., representing creators, artists)',
  'Archivist or Digital Curator (e.g., managing digital collections)',
  'Product Manager (e.g., overseeing tech or media product development)',
  'Educator or Trainer (e.g., teaching media, AI ethics, or content creation)',
  'Fashion Stylist or E-commerce Specialist (e.g., digital styling, product media)',
  'Journalist or Editor (e.g., creating and managing original content) Other (not included in list)',
]

const HomeContent = () => {
  return (
    <MainPanel>
      <div className="contract">
        <h3 className="title">Manage License, Provenance and Opt-out</h3>
        <p>This record covers</p>
        <AssetsUploadSection />
      </div>
    </MainPanel>
  )
}

const Home = () => {
  const [selectedIndustry, setSelectedIndustry] = useState()
  const [selectedIndustryRole, setSelectedIndustryRole] = useState()
  const dispatch = useDispatch()
  const {
    auth: { user },
  } = useSelector((state) => state)
  const [industryDetailsModal, setIndustryDetailsModal] = useState(
    !localStorage.getItem('industry_details'),
  )

  const handleGetUserAgreements = async () => {
    await dispatch(getUserAgreements())
  }

  const handleGetAssets = async () => {
    await dispatch(getAssets())
  }

  const resetAnySigningAsset = async () => {
    await dispatch(resetSigningAssets())
  }

  const selectIndustry = (value) => {
    triggerGoogleAnalyticsEvent('select_industry', {
      value,
      userId: user?.id,
    })
    setSelectedIndustry(value)
  }

  const selectIndustryRole = (value) => {
    triggerGoogleAnalyticsEvent('select_industry_role', {
      value,
      userId: user?.id,
    })
    setSelectedIndustryRole(value)
  }

  const submitIndustryDetails = async (e) => {
    e.preventDefault()
    triggerGoogleAnalyticsEvent('submit_industry_details', {
      userId: user?.id,
    })
    try {
      if (selectedIndustry && selectedIndustryRole) {
        const payload = {
          userIndustry: selectedIndustry,
          userIndustryRole: selectedIndustryRole,
        }
        await instance.post('/users/industry-details', payload)
        localStorage.setItem('industry_details', JSON.stringify(payload))
      }
    } catch (error) {
      // Continue
    }
    setIndustryDetailsModal(false)
  }

  useEffect(() => {
    handleGetAssets()
    handleGetUserAgreements()
    resetAnySigningAsset()
  }, [])

  return (
    <div className="tikbox-home">
      <div className="tikbox-home__content">
        <SidePanel />
        <HomeContent />
      </div>
      <TikBoxModal
        open={industryDetailsModal}
        closable={false}
        maxW="600px"
        toggle={() => {}}>
        <form className="industry-details-form">
          <h6>
            It's great to have you here. In order to help protect and
            commercialise your assets, we'd like to know a little more about you
          </h6>
          <div className="industry-container" style={{ marginBottom: 10 }}>
            <p style={{ margin: 0 }}>What industry are you from? </p>
            <Select
              defaultValue="Select industry"
              style={{
                marginRight: 7,
                height: 48,
                width: '100%',
                borderColor: 'red',
              }}
              popupClassName="custom-select-dropdown"
              onChange={selectIndustry}
              options={industries.map((industry) => {
                return { value: industry, label: industry }
              })}
            />
          </div>
          <div className="industry-role-container">
            <p style={{ margin: 0 }}>What function do you work in? </p>
            <Select
              defaultValue="Select"
              style={{
                marginRight: 7,
                height: 48,
                width: '100%',
                borderColor: 'red',
              }}
              popupClassName="custom-select-dropdown"
              onChange={selectIndustryRole}
              options={industriesRoles.map((role) => {
                return { value: role, label: role }
              })}
            />
          </div>

          <div className="btn-container">
            <button className="use-now-btn" onClick={submitIndustryDetails}>
              Next
            </button>
          </div>
        </form>
      </TikBoxModal>
    </div>
  )
}

export default Home
